
import { defineComponent, onMounted, ref } from "vue";
import State from "@/components/reusable/State.vue";
import Gender from "@/components/reusable/Gender.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components";
import { useRouter } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import Address from "@/components/reusable/Address.vue";

export default defineComponent({
  name: "individual-client-personal-information",
  components: {
    ErrorMessage,
    Field,
    Form,
    State,
    Gender,
    Address,
  },
  props: {
    cartItemId: { type: String, required: true },
  },
  data() {
    return {
      acceptTerms: false,
      displayDates: false,
      startDateMax: variables.currentDate(),
      endDateMin: variables.currentDate(),
      endDateMax: "" as any,
      profileDetails: {
        alternativePhoneNumber: "",
        avatar: "",
        dateOfBirth: "",
        emailAddress: "",
        firstName: "",
        gender: "",
        homeAddress: "",
        identificationExpiryDate: "",
        identificationIssueDate: "",
        identificationNumber: "",
        lastName: "",
        localGovernmentArea: "",
        maritalStatus: "",
        meansOfIdentification: "",
        identificationImage: "",
        middleName: "",
        phoneNumber: "",
        state: "",
        ward: "",
        zipCode: "",
        bvn: "",
      },
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const profileDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      phoneNumber: Yup.number()
        .required()
        .min(11)
        .label("Phone number"),
      alternativePhoneNumber: Yup.string()
        .notRequired()
        .label("Alternative phone number"),
      dateOfBirth: Yup.date()
        .required()
        .label("Date of birth"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      maritalStatus: Yup.mixed()
        .required()
        .label("Martial status"),
      state: Yup.mixed()
        .required()
        .label("State"),
      localGovernmentArea: Yup.mixed()
        .required()
        .label("Local government area"),
      zipCode: Yup.number()
        .notRequired()
        .label("Zip code"),
      meansOfIdentification: Yup.mixed()
        .notRequired()
        .label("Means of identification"),
      identificationNumber: Yup.string()
        .notRequired()
        // .min(10)
        .label("Means of identification number"),
      identificationImage: Yup.mixed()
        .notRequired()
        .label("Identification image"),
      identificationIssueDate: Yup.date()
        .notRequired()
        .label("Date of issue"),
      identificationExpiryDate: Yup.date()
        .notRequired()
        .label("Date of expiry"),
      bvn: Yup.string()
        .notRequired()
        .min(11)
        .max(11)
        .label("Bank verification number"),
      homeAddress: Yup.string()
        .required()
        .label("Home address"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions"),
    });

    const updatePersonalInformation = (values) => {
      // console.log(Object.keys(user));

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(variables.PROFILE_PERSONAL_INFORMATION, values)
              .then((response) => {
                variables.toastAlert(response.data.message, "success");

                ApiService.get(variables.PROFILE_PERSONAL_INFORMATION).then(
                  ({ data }) => {
                    if (data.data.avatar != "") {
                      const avatar = window.localStorage.getItem("userAvatar");
                      if (data.data.avatar != avatar) {
                        window.localStorage.setItem(
                          "userAvatar",
                          data.data.avatar
                        );
                        store.commit(Mutations.SET_AVATAR, data.data.avatar);
                      }
                    }
                  }
                );

                // Redirect to profile overview after update
                if (props.cartItemId == undefined) {
                  const cartCount = store.getters.getCartCount;
                  let route = "";

                  if (
                    store.getters.currentUser.role == "cooperative-admin-user"
                  ) {
                    route =
                      cartCount > 0
                        ? "/cooperative/cart"
                        : "/cooperative/policies";
                  } else {
                    route =
                      cartCount > 0
                        ? "/insurance-packages/cart"
                        : "/insurance-packages";
                  }

                  router.push({
                    path: route,
                  });
                }

                // Redirect back to invoice if there was a redirect initially
                if (props.cartItemId != undefined) {
                  router.push({
                    path:
                      "/insurance-packages/cart/debit-note/" + props.cartItemId,
                  });
                }
              })
              .catch(({ response }) => {
                variables.toastAlert(response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Personal Details", ["Profile"]);
      MenuComponent.reinitialization();
    });

    return {
      submitButton1,
      updatePersonalInformation,
      profileDetailsValidator,
    };
  },
  created() {
    // Get user personal information on page load
    this.getPersonalInformation();

    this.endDateMax = variables.addYearToDate(variables.currentDate(), 1);

    //Set page title
    document.title = "Personal Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    removeImage() {
      this.profileDetails.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.profileDetails.avatar = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    getPersonalInformation() {
      //Fetch individual client profile personal infomation
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.PROFILE_PERSONAL_INFORMATION)
          .then(({ data }) => {
            //Assign data to form fields
            this.profileDetails.gender = data?.data?.gender?.publicId;
            this.profileDetails.state = data.data.state.publicId;
            this.profileDetails.localGovernmentArea =
              data.data.localGovernmentArea.publicId;

            if (data.data.avatar != "") {
              const avatar = window.localStorage.getItem("userAvatar");
              if (data.data.avatar == avatar) {
                this.profileDetails.avatar =
                  variables.AVATAR_ROUTE + data.data.avatar;
              } else {
                window.localStorage.setItem("userAvatar", data.data.avatar);
              }
            } else {
              this.profileDetails.avatar = "/media/avatars/blank.png";
            }
            // this.profileDetails.avatar = data.data.avatar;
            this.profileDetails.firstName = data.data.firstName;
            this.profileDetails.middleName = data.data.middleName;
            this.profileDetails.lastName = data.data.lastName;
            this.profileDetails.emailAddress = data.data.emailAddress;
            this.profileDetails.phoneNumber = data.data.phoneNumber;
            this.profileDetails.alternativePhoneNumber =
              data.data.alternativePhoneNumber;
            this.profileDetails.dateOfBirth = data.data.dateOfBirth;
            this.profileDetails.homeAddress = data.data.homeAddress;
            this.profileDetails.identificationExpiryDate =
              data.data.identificationExpiryDate;
            this.profileDetails.identificationIssueDate =
              data.data.identificationIssueDate;
            this.profileDetails.identificationNumber =
              data.data.identificationNumber;
            this.profileDetails.maritalStatus = data.data.maritalStatus;
            this.profileDetails.meansOfIdentification =
              data.data.meansOfIdentification;
            this.profileDetails.ward = data.data.ward;
            this.profileDetails.bvn = data.data.bvn;
            this.profileDetails.zipCode = data.data.zipCode;

            if (
              this.profileDetails.meansOfIdentification == "Drivers License" ||
              this.profileDetails.meansOfIdentification ==
                "International Passport"
            ) {
              this.displayDates = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;

      value == "Drivers License" || value == "International Passport"
        ? (this.displayDates = true)
        : (this.displayDates = false);
      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;

      if (dateIssued.value) {
        dateIssued.value != "" ? dateIssued.value : "";
        dateExpired.value != "" ? dateExpired.value : "";
      }

      // (<HTMLInputElement>document.getElementById("date-of-issue")).value;
    },
    identificationImage(event) {
      const selectedImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.profileDetails.identificationImage = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    getStartDate(event) {
      const today = new Date(this.profileDetails.identificationIssueDate);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const year = today.getFullYear() + 1;

      this.startDateMax = variables.addYearToDate(event.target.value, 10);
      this.endDateMax = variables.addYearToDate(event.target.value, 10);
      this.endDateMin = year + "-" + mm + "-" + dd;
    },
  },
});
